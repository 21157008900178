// styling
@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';
@import '@mantine/charts/styles.css';

:root [cds-theme] {
  div.left-nav {
    div.branding {
      justify-content: center;
      img {
        max-width: 200px !important;
        width: 100%;
      }
    }
  }

  div.login-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background: linear-gradient(to right, #ec7c2c, #e1552c, #d83e2d);

    div.login-wrapper-inner {
      box-shadow: -6px 7px 22px -2px rgba(0, 0, 0, 0.5) !important;

      img.login-wrapper-logo {
        width: 200px !important;
        right: 165px !important;
        top: 100px !important;
      }
    }

    div.login-wrapper-form {
      button.login-button {
        border-radius: 8px;
        height: 48px;
        color: white;
        background-color: var(--primary-orange);
        margin-top: 32px;

        &:hover {
          background-color: var(--secondary-orange);
        }

        &:disabled {
          &:hover {
            background-color: var(--primary-gray);
          }
        }
      }

      p.login-title {
        font-weight: 400px !important;
        font-size: 1rem !important;
        line-height: 25px;
      }

      div.login-group {
        input.username {
          margin-bottom: 16px !important;
        }
      }
    }
  }

  .active-channel.m-auto {
    background-color: #f3f3f3;

    &:hover {
      span {
        color: var(--primary-orange) !important;
      }

      svg {
        color: var(--primary-orange) !important;
      }
    }
  }

  button.active-channel {
    span.channel-label {
      &:hover {
        color: var(--primary-orange) !important;
      }
    }
  }

  .main-nav {
    .nav-group.active {
      background-color: unset !important;
    }

    .nav-group {
      .nav-link {
        svg {
          color: var(--primary-gray);
        }

        span {
          color: var(--primary-gray);
        }
      }

      .nav-link.active {
        border-right-color: var(--primary-orange) !important;

        svg {
          color: var(--primary-orange);
        }

        span {
          color: var(--primary-orange);
        }
      }
    }
  }

  .left-nav {
    .settings-nav-container {
      hr {
        box-shadow: unset !important;
        border: 1px solid var(--soft-gray);
      }
    }
  }

  button.user-menu-btn {
    &:hover {
      background-color: var(--soft-gray);
    }

    div.user-circle {
      background-image: unset !important;
      background-color: var(--primary-orange);
    }
  }

  button.alerts-button {
    &:hover {
      background-color: var(--soft-gray);
    }
  }

  li.pagination-previous {
    button.button {
      background-color: white;
      color: var(--soft-gray);
    }
  }

  li.pagination-next {
    button.button {
      background-color: white;
      color: var(--soft-gray);
    }
  }

  li.page-number-button {
    &:hover {
      background-color: #f6f6f6;
    }
  }

  // div.empty-state {
  //     &:hover {
  //         background-color: unset;
  //     }
  // }

  tbody {
    td {
      border-bottom: 1px solid var(--soft-gray);
    }
  }

  .clr-control-label ::after {
    background: var(--primary-orange) !important;
  }

  .button-ghost {
    &:hover {
      background-color: unset;
    }
  }

  tr {
    &:hover {
      background-color: #f6f6f6 !important;
    }
  }

  div.table-wrapper {
    button.toggle-search-filter-row {
      background-color: white;
      border-color: unset;

      svg {
        color: var(--primary-gray);
      }

      &:hover {
        svg {
          color: var(--primary-orange);
        }
      }
    }
  }

  .clr-checkbox-wrapper input[type='checkbox']:checked + .clr-control-label::before {
    outline: none;
  }

  .button.primary:not(:disabled) {
    border-radius: 8px;

    &:hover {
      background-color: var(--secondary-orange);
    }
  }

  .btn.btn-primary:not(:disabled) {
    border-radius: 8px;

    &:hover {
      background-color: var(--secondary-orange);
    }
  }

  .market-table-head-cells {
    font-weight: bold;
    color: #333333;
    div:nth-child(1) {
      div:nth-child(1) {
        width: 100%;
        button:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .mantine-select {
    border-radius: 12px !important;
    padding: 0px 20px !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6d6d6d;
  }

  .mantine-select-dropdown {
    border-radius: 12px;
    padding: 0px;
  }

  .mantine-select-option {
    padding: 12px 16px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #333333;
  }

  .mantine-input {
    border-radius: 12px !important;
    padding: 0px 20px !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #333333;
  }

  .mantine-tab {
    padding: 8px 16px;
  }

  .mantine-tab-label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #333333;
  }

  vdr-theme-switcher.dropdown-item {
    display: none !important;
  }

  --border-radius: 18px;
  --primary-orange: #f06937;
  --secondary-orange: #ed5022;
  --primary-gray: #888888;
  --soft-gray: #e7e7e7;
  --color-left-nav-bg: #ffffff;
  --color-primary-500: var(--primary-orange);
  --color-primary-700: var(--primary-orange);
  --color-text-active: var(--primary-orange);
  --color-button-ghost-hover-text: var(--primary-orange);
  --clr-forms-checkbox-disabled-label-color: var(--primary-orange);
  --clr-forms-checkbox-background-color: var(--primary-orange);
}
